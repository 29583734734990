<template>
  <ClientOnly>
    <span
      v-if="isLoggedIn && customerName"
      class="cursor-pointer"
      @click.prevent="toggleAppAccount()"
    >
      {{ customerName }}
    </span>
    <span
      v-if="!isLoggedIn"
      class="cursor-pointer"
      data-test="app-header-login-button"
      @click.prevent="toggleAppAccount()"
    >
      {{ t('log_in') }}
    </span>
    <span
      v-else
      class="cursor-pointer"
      data-test="app-header-logout-button"
      @click.prevent="logOutCustomer({ userInitializedAction: true })"
    >
      {{ t('log_out') }}
    </span>
  </ClientOnly>
</template>

<script lang="ts" setup>
import { useDrawer } from '@base/components/AppDrawer/useDrawer'
import { useCustomer } from '../../composables/useCustomer'

const { t } = useI18n()
const { toggleAppAccount } = useDrawer()
const { customerData, isLoggedIn, logOutCustomer } = useCustomer()

const customerName = computed(() => customerData.value?.customer?.fullname ?? '')
</script>
